import { Action as ReduxAction } from 'redux';
import { Status } from '../../services/sculkwire';

export type State = Status | null;

export enum Action {
  STATUS_GET_REQUEST = 'STATUS_GET_REQUEST',
  STATUS_GET_SUCCESS = 'STATUS_GET_SUCCESS',
  STATUS_GET_ERROR = 'STATUS_GET_ERROR',
}

export type StatusGetRequestAction = ReduxAction<Action.STATUS_GET_REQUEST>;

export interface StatusGetSuccessAction extends ReduxAction<Action.STATUS_GET_SUCCESS> {
  payload: {
    status: Status;
  };
}

export interface StatusGetErrorAction extends ReduxAction<Action.STATUS_GET_ERROR> {
  payload: {
    error: unknown;
  };
}
