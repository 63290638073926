import React from 'react';
import useStyles from './useStyles';
import Config from '../../modules/config';

function MapPage(): JSX.Element {
  const { classes } = useStyles();
  return (
    <iframe className={classes.frame} title="map" src={new Config().getDynmapUrl()} frameBorder="0" />
  );
  // return (<div className={styles.frame} style={{ background: 'green' }} />);
}

export default MapPage;
