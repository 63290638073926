import { PlayerStat } from '../../services/sculkwire';
import { State as AppState } from '..';
import hash from './hash';

export const selectLeaderboard = (
  statistic: string,
  entity?: string,
  material?: string,
  limit?: number,
) => (state: AppState): PlayerStat[] | undefined => state
  .leaderboards[hash(statistic, entity, material, limit)];
