function hash(
  statistic: string,
  entity?: string,
  material?: string,
  limit?: number,
) {
  let result = statistic;
  if (entity) result = `${result}:${entity}`;
  if (material) result = `${result}:${material}`;
  if (limit) result = `${result}:${limit}`;
  return result;
}

export default hash;
