import { Action as ReduxAction } from 'redux';
import { Statistic } from '../../services/sculkwire';

export type State = Statistic[];

export enum Action {
  STATISTICS_GET_REQUEST = 'STATISTICS_GET_REQUEST',
  STATISTICS_GET_SUCCESS = 'STATISTICS_GET_SUCCESS',
  STATISTICS_GET_ERROR = 'STATISTICS_GET_ERROR',
}

export type StatisticsGetRequestAction = ReduxAction<Action.STATISTICS_GET_REQUEST>;

export interface StatisticsGetSuccessAction extends ReduxAction<Action.STATISTICS_GET_SUCCESS> {
  payload: {
    statistics: Statistic[];
  };
}

export interface StatisticsGetErrorAction extends ReduxAction<Action.STATISTICS_GET_ERROR> {
  payload: {
    error: unknown;
  };
}
