import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Feature, selectFeature } from '../../store/features';
import Carousel from '../Carousel';
import ServerStatus from '../ServerStatus';
import PlayersOnline from '../PlayersOnline';
import Clock from '../Clock';
import Weather from '../Weather';
import Leaderboard from '../Leaderboard';
import useStyles from './useStyles';

function HomePage(): JSX.Element {
  const { classes } = useStyles();
  const weatherEnabled = useSelector(selectFeature(Feature.WEATHER));
  const carouselEnabled = useSelector(selectFeature(Feature.CAROUSEL));
  return (
    <>
      {carouselEnabled && <Carousel />}
      <div className={classes.gridContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <div className={classes.wrapper}>
              <ServerStatus />
              <div className={classes.spacer} />
              <PlayersOnline />
              <div className={classes.spacer} />
              <Clock />
              {weatherEnabled && (
                <>
                  <div className={classes.spacer} />
                  <Weather />
                </>
              )}
              <div className={classes.spacer} />
              <Leaderboard statistic="PLAYER_KILLS" />
              <div className={classes.spacer} />
              <Leaderboard statistic="KILL_ENTITY" entity="ZOMBIE" />
              <div className={classes.spacer} />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Leaderboard statistic="TOTAL_WORLD_TIME" />
            <div className={classes.spacer} />
            <Leaderboard statistic="MINE_BLOCK" material="STONE" />
            <div className={classes.spacer} />
            <Leaderboard statistic="MINE_BLOCK" material="WHEAT" />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default HomePage;
