import { Dispatch } from '..';
import Config from '../../modules/config';
import SculkwireService, { Statistic } from '../../services/sculkwire';
import {
  Action,
  StatisticsGetRequestAction,
  StatisticsGetSuccessAction,
  StatisticsGetErrorAction,
} from './types';

export const statisticsGetRequest = (): StatisticsGetRequestAction => ({
  type: Action.STATISTICS_GET_REQUEST,
});

export const statisticsGetSuccess = (statistics: Statistic[]): StatisticsGetSuccessAction => ({
  type: Action.STATISTICS_GET_SUCCESS,
  payload: { statistics },
});

export const statisticsGetError = (error: unknown): StatisticsGetErrorAction => ({
  type: Action.STATISTICS_GET_ERROR,
  payload: { error },
});

export const getStatistics = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(statisticsGetRequest());
  try {
    const config = new Config();
    const statistics = await new SculkwireService(config.getSculkwireUrl()).getStatistics();
    dispatch(statisticsGetSuccess(statistics));
  } catch (error) {
    dispatch(statisticsGetError(error));
  }
};
