import React from 'react';
import { useParams } from 'react-router-dom';
import Leaderboard from '../Leaderboard';
import useStyles from './useStyles';

function LeaderboardPage(): JSX.Element {
  const { classes } = useStyles();
  const { statistic } = useParams();
  const params = new URLSearchParams(window ? window.location.search : {});
  const entity = params.get('entity') ?? undefined;
  const material = params.get('material') ?? undefined;
  const limitString = params.get('limit');
  const limit = limitString ? Number.parseInt(limitString, 10) : undefined;

  return (
    <div className={classes.container}>
      <Leaderboard
        statistic={statistic ?? ''}
        entity={entity}
        material={material}
        limit={limit}
      />
    </div>
  );
}

export default LeaderboardPage;
