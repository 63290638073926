import { Action as ReduxAction } from 'redux';
import { Theme } from '@mui/material/styles';

export type State = Theme;

export enum Action {
  THEME_TYPE_SET = 'THEME_TYPE_SET',
}

export interface ThemeTypeSetAction extends ReduxAction<Action.THEME_TYPE_SET> {
  payload: {
    paletteMode: 'dark' | 'light';
  };
}
