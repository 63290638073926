import { Dispatch } from '..';
import Config from '../../modules/config';
import SculkwireService, { Status } from '../../services/sculkwire';
import {
  Action,
  StatusGetRequestAction,
  StatusGetSuccessAction,
  StatusGetErrorAction,
} from './types';

export const statusGetRequest = (): StatusGetRequestAction => ({
  type: Action.STATUS_GET_REQUEST,
});

export const statusGetSuccess = (status: Status): StatusGetSuccessAction => ({
  type: Action.STATUS_GET_SUCCESS,
  payload: { status },
});

export const statusGetError = (error: unknown): StatusGetErrorAction => ({
  type: Action.STATUS_GET_ERROR,
  payload: { error },
});

export const getStatus = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(statusGetRequest());
  try {
    const config = new Config();
    const status = await new SculkwireService(config.getSculkwireUrl()).getStatus();
    dispatch(statusGetSuccess(status));
  } catch (error) {
    dispatch(statusGetError(error));
  }
};
