import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Button,
  Typography,
  CircularProgress,
  Grid,
} from '@mui/material';
import clsx from 'clsx';
import { getOnlinePlayers, selectOnlinePlayers } from '../../store/players';
import { selectLoading } from '../../store/loading';
import useStyles from './useStyles';
import { Dispatch } from '../../store';

function PlayersOnline(): JSX.Element {
  const { classes } = useStyles();
  const players = useSelector(selectOnlinePlayers);
  const loading = useSelector(selectLoading('PLAYERS_ONLINE_GET'));
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch(getOnlinePlayers());
  }, [dispatch]);

  return (
    <Card className={classes.root}>
      <CardHeader title="Players Online" subheader="mc.dimension-seven.net" />
      {loading && <CircularProgress className={classes.loader} />}
      <CardContent className={clsx(classes.content, { loading })}>
        <Grid className={classes.grid} container spacing={1}>
          {players && players.map((player) => (
            <Grid
              item
              container
              className={classes.grid}
              key={player.uuid}
              xs={12}
              sm={6}
              md={4}
              lg={6}
              spacing={3}
            >
              <Link to={`/players/${player.uuid}`} className={classes.link}>
                <div className={classes.player}>
                  <img src={`https://mc-heads.net/head/${player.uuid}`} alt="player-head" />
                  <Typography variant="h5">
                    {player.name}
                  </Typography>
                </div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={(): void => { dispatch(getOnlinePlayers()); }} size="small">Refresh</Button>
      </CardActions>
    </Card>
  );
}

export default PlayersOnline;
