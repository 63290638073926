import { Action as ReduxAction } from 'redux';
import { World } from '../../services/sculkwire';

export type State = World[] | null;

export enum Action {
  WORLDS_GET_REQUEST = 'WORLDS_GET_REQUEST',
  WORLDS_GET_SUCCESS = 'WORLDS_GET_SUCCESS',
  WORLDS_GET_ERROR = 'WORLDS_GET_ERROR',
}

export type WorldsGetRequestAction = ReduxAction<Action.WORLDS_GET_REQUEST>;

export interface WorldsGetSuccessAction extends ReduxAction<Action.WORLDS_GET_SUCCESS> {
  payload: {
    worlds: World[];
  };
}

export interface WorldsGetErrorAction extends ReduxAction<Action.WORLDS_GET_ERROR> {
  payload: {
    error: unknown;
  };
}
