import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  spacer: theme.mixins.toolbar as CSSObject,
}));

export default useStyles;
