import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Button,
  FormControlLabel,
  CircularProgress,
  Switch,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { Dispatch } from '../../store';
import { getWorlds, selectOverworld } from '../../store/worlds';
import { selectLoading } from '../../store/loading';
import useStyles from './useStyles';

function Clock(): JSX.Element | null {
  const UPDATE_INTERVAL = 1000 * 60;
  const { classes } = useStyles();
  const overworld = useSelector(selectOverworld);
  const loading = useSelector(selectLoading('WORLDS_GET'));
  const dispatch = useDispatch<Dispatch>();
  const [clockSprite, setClockSprite] = useState('clock_00.png');
  const [digital, setDigital] = useState(localStorage.getItem('clockType') === 'digital');

  const padZero = (n: number): string => {
    if (n < 10) {
      return `0${n}`;
    }
    return `${n}`;
  };

  const getHour = (): number => {
    if (!overworld) return 12;
    const adjustedTime = (overworld.time + 6000) % 24000;
    return Math.floor(adjustedTime / 1000);
  };

  const getMinute = (): number => {
    if (!overworld) return 0;
    const elapsedTicks = overworld.time % 1000;
    const elapsedPercent = (elapsedTicks / 1000);
    return Math.floor(elapsedPercent * 60);
  };

  const onDigitalChange = (): void => {
    localStorage.setItem('clockType', !digital ? 'digital' : 'analog');
    setDigital(!digital);
  };

  useEffect(() => {
    dispatch(getWorlds());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getWorlds());
    }, UPDATE_INTERVAL);
    return (): void => clearInterval(interval);
  }, [dispatch, UPDATE_INTERVAL]);

  useEffect(() => {
    if (overworld) {
      let i = Math.floor(overworld.time / 375);
      i = (i + 47) % 64;
      setClockSprite(`clock_${padZero(i)}.png`);
    }
  }, [overworld]);

  return (
    <Card className={classes.root}>
      <CardHeader title="Clock" subheader="mc.dimension-seven.net" />
      {loading && <CircularProgress className={classes.loader} />}
      <CardContent className={clsx(classes.content, { loading })}>
        {!digital && (
          <CardMedia title="Clock">
            <img className={classes.clock} src={`/clock/${clockSprite}`} alt="clock" />
          </CardMedia>
        )}
        {digital && (
          <Typography variant="h1" className={classes.digital}>
            {`${padZero(getHour())}:${padZero(getMinute())}`}
          </Typography>
        )}
      </CardContent>
      <CardActions className={classes.actions}>
        <FormControlLabel
          control={<Switch onChange={onDigitalChange} checked={digital} />}
          label="Digital"
          labelPlacement="start"
        />
        <Button onClick={(): void => { dispatch(getWorlds()); }} size="small">Refresh</Button>
      </CardActions>
    </Card>
  );
}

export default Clock;
