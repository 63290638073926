import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  gridContainer: {
    padding: theme.spacing(3),
  },
  spacer: {
    height: theme.spacing(3),
  },
  wrapper: {
    width: '100%',
  },
}));

export default useStyles;
