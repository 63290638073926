import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import clsx from 'clsx';
import { Dispatch } from '../../store';
import { getStatus, selectStatus } from '../../store/status';
import { selectLoading } from '../../store/loading';
import useStyles from './useStyles';

function ServerStatus(): JSX.Element | null {
  const { classes } = useStyles();
  const status = useSelector(selectStatus);
  const loading = useSelector(selectLoading('STATUS_GET'));
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);

  return (
    <Card className={classes.root}>
      <CardHeader title="Server Status" subheader="mc.dimension-seven.net" />
      {loading && <CircularProgress className={classes.loader} />}
      <CardContent className={clsx(classes.content, { loading })}>
        <CardMedia title="Server Status">
          <img className={classes.favicon} src={status ? status.icon : undefined} alt="favicon" />
        </CardMedia>
        <Typography variant="h5">
          {status && status.motd}
        </Typography>
        <Typography color="textSecondary">
          {status && `${status.numPlayers} / ${status.maxPlayers} Players`}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={(): void => { dispatch(getStatus()); }} size="small">Refresh</Button>
      </CardActions>
    </Card>
  );
}

export default ServerStatus;
