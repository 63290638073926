import {
  Action,
  ThemeTypeSetAction,
} from './types';

export const setThemeType = (paletteMode: 'dark' | 'light'): ThemeTypeSetAction => ({
  type: Action.THEME_TYPE_SET,
  payload: {
    paletteMode,
  },
});
