import { AnyAction } from 'redux';
import { Action, State } from './types';
import hash from './hash';

export const DEFAULT_STATE: State = {};

// eslint-disable-next-line default-param-last
function reducer(state = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case Action.LEADERBOARD_GET_SUCCESS: {
      const {
        statistic,
        data,
        entity,
        material,
        limit,
      } = action.payload;
      return {
        ...state,
        [hash(statistic, entity, material, limit)]: data,
      };
    }
    default:
      return state;
  }
}

export default reducer;
