import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
  },
  logo: {
    maxWidth: 500,
    animation: '$rotation 10s infinite linear',
  },
}));

export default useStyles;
