import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  frame: {
    width: '100%',
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    margin: 0,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - ${theme.spacing(8)}px)`,
    },
  },
}));

export default useStyles;
