import { Action as ReduxAction } from 'redux';
import { PlayerStat } from '../../services/sculkwire';

export type State = { [key: string]: PlayerStat[] };

export enum Action {
  LEADERBOARD_GET_REQUEST = 'LEADERBOARD_GET_REQUEST',
  LEADERBOARD_GET_SUCCESS = 'LEADERBOARD_GET_SUCCESS',
  LEADERBOARD_GET_ERROR = 'LEADERBOARD_GET_ERROR',
}

export type LeaderboardGetRequestAction = ReduxAction<Action.LEADERBOARD_GET_REQUEST>;

export interface LeaderboardGetSuccessAction extends ReduxAction<Action.LEADERBOARD_GET_SUCCESS> {
  payload: {
    statistic: string;
    data: PlayerStat[];
    entity?: string;
    material?: string;
    limit?: number;
  };
}

export interface LeaderboardGetErrorAction extends ReduxAction<Action.LEADERBOARD_GET_ERROR> {
  payload: {
    error: unknown;
  };
}
