import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import useStyles from './useStyles';

function ConstructionPage(): JSX.Element {
  const { classes } = useStyles();
  return (
    <div className={classes.page}>
      <Logo className={classes.logo} />
      <h1>
        Under Construction
      </h1>
    </div>
  );
}

export default ConstructionPage;
