import {
  Client,
  Entity,
  Material,
  Player,
  Leaderboard,
  Statistic,
  Status,
  World,
} from '.';

class FetchClient implements Client {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getStatus(): Promise<Status> {
    const url = `${this.baseUrl}/status`;
    const response = await fetch(url);
    return response.json();
  }

  async getOnlinePlayers(): Promise<Player[]> {
    const url = `${this.baseUrl}/players?online=true`;
    const response = await fetch(url);
    return response.json();
  }

  async getPlayer(id: string): Promise<Player> {
    const url = `${this.baseUrl}/players/${id}`;
    const response = await fetch(url);
    return response.json();
  }

  async getPlayers(): Promise<Player[]> {
    const url = `${this.baseUrl}/players`;
    const response = await fetch(url);
    return response.json();
  }

  async getWorlds(): Promise<World[]> {
    const url = `${this.baseUrl}/worlds`;
    const response = await fetch(url);
    return response.json();
  }

  async getLeaderboard(
    statistic: string,
    entity?: string,
    material?: string,
    limit?: number,
  ): Promise<Leaderboard> {
    let url = `${this.baseUrl}/stats?statistic=${statistic}`;
    if (entity) { url += `&entity=${entity}`; }
    if (material) { url += `&material=${material}`; }
    if (limit) { url += `&limit=${limit}`; }
    const response = await fetch(url);
    return response.json();
  }

  async getStatistics(): Promise<Statistic[]> {
    const url = `${this.baseUrl}/statistics`;
    const response = await fetch(url);
    return response.json();
  }

  async getMaterials(): Promise<Material[]> {
    const url = `${this.baseUrl}/materials`;
    const response = await fetch(url);
    return response.json();
  }

  async getEntities(): Promise<Entity[]> {
    const url = `${this.baseUrl}/entities`;
    const response = await fetch(url);
    return response.json();
  }
}

export default FetchClient;
