import { combineReducers } from 'redux';
import loading from './loading';
import error from './error';
import status from './status';
import theme from './theme';
import players from './players';
import worlds from './worlds';
import features from './features';
import leaderboards from './leaderboards';
import statistics from './statistics';
import materials from './materials';
import entities from './entities';

const reducer = combineReducers({
  status,
  players,
  loading,
  error,
  theme,
  worlds,
  features,
  leaderboards,
  statistics,
  materials,
  entities,
});

export default reducer;
