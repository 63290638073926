import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from 'react-router';
import { selectTheme } from '../../store/theme';
import { Feature, selectFeature } from '../../store/features';
import Header from '../Header';
import Drawer from '../Drawer';
import MainMenu from '../MainMenu';
import HomePage from '../HomePage';
import MapPage from '../MapPage';
import PlayerPage from '../PlayerPage';
import PlayersPage from '../PlayersPage';
import ConstructionPage from '../ConstructionPage';
import NotFoundPage from '../NotFoundPage';
import LeaderboardPage from '../LeaderboardPage';
import LeaderboardsPage from '../LeaderboardsPage';
import useStyles from './useStyles';

function App(): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { classes } = useStyles();
  const theme = useSelector(selectTheme);
  const mapEnabled = useSelector(selectFeature(Feature.MAP));
  const donationsEnabled = useSelector(selectFeature(Feature.DONATIONS));
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Header onMenuClick={(): void => setDrawerOpen(true)} data-test-id="header" />
        <Drawer open={drawerOpen} onClose={(): void => setDrawerOpen(false)} data-test-id="drawer">
          <MainMenu />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.spacer} />
          <Routes>
            <Route path="/" element={<HomePage />} />
            {mapEnabled && <Route path="/map" element={<MapPage />} />}
            {donationsEnabled && <Route path="/donate" element={<ConstructionPage />} />}
            <Route path="/players" element={<PlayersPage />} />
            <Route path="/players/:uuid" element={<PlayerPage />} />
            <Route path="/leaderboards" element={<LeaderboardsPage />} />
            <Route path="/leaderboards/:statistic" element={<LeaderboardPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
