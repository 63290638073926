import { Action as ReduxAction } from 'redux';
import { Entity } from '../../services/sculkwire';

export type State = Entity[];

export enum Action {
  ENTITIES_GET_REQUEST = 'ENTITIES_GET_REQUEST',
  ENTITIES_GET_SUCCESS = 'ENTITIES_GET_SUCCESS',
  ENTITIES_GET_ERROR = 'ENTITIES_GET_ERROR',
}

export type EntitiesGetRequestAction = ReduxAction<Action.ENTITIES_GET_REQUEST>;

export interface EntitiesGetSuccessAction extends ReduxAction<Action.ENTITIES_GET_SUCCESS> {
  payload: {
    entities: Entity[];
  };
}

export interface EntitiesGetErrorAction extends ReduxAction<Action.ENTITIES_GET_ERROR> {
  payload: {
    error: unknown;
  };
}
