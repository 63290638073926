import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { idToTitle } from '../../modules/strings';
import { Dispatch } from '../../store';
import { getLeaderboard, selectLeaderboard } from '../../store/leaderboards';
import { selectLoading } from '../../store/loading';
import useStyles from './useStyles';
import { Props } from './types';
import transformTicksToTime from './transformTicksToTime';

function Leaderboard(props: Props): JSX.Element {
  const {
    statistic,
    entity,
    material,
    limit,
    title,
    transformScore,
  } = props;
  const { classes } = useStyles();
  const leaderboard = useSelector(selectLeaderboard(statistic, entity, material, limit));
  const loading = useSelector(selectLoading('LEADERBOARD_GET'));
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch(getLeaderboard(statistic, entity, material, limit));
  }, [dispatch, statistic, entity, material, limit]);

  function getTitle(): string {
    const s = idToTitle(statistic);
    const m = idToTitle(material);
    const e = idToTitle(entity);
    return `${s} ${m ?? e ?? ''}`.trim();
  }

  function getTransformScore(): ((score: number) => string | number) | undefined {
    let result = transformScore;
    if (!result) {
      switch (statistic) {
        case 'SNEAK_TIME':
        case 'TIME_SINCE_DEATH':
        case 'TIME_SINCE_REST':
        case 'TOTAL_WORLD_TIME':
          result = transformTicksToTime;
          break;
        default:
      }
    }
    return result;
  }

  const transform = getTransformScore();

  return (
    <Card className={classes.root}>
      <CardHeader
        title="Leaderboard"
        subheader={title ?? getTitle()}
      />
      {loading && <CircularProgress className={classes.loader} />}
      <CardContent className={clsx(classes.content, { loading })}>
        {leaderboard && leaderboard.map((stat, i) => (
          <Link to={`/players/${stat.uuid}`} className={classes.link} key={stat.uuid}>
            <div className={classes.player}>
              <img src={`https://mc-heads.net/head/${stat.uuid}`} alt="player-head" />
              <Typography variant="h5">
                {`#${i + 1} ${stat.name}`}
              </Typography>
              <Typography variant="h3">
                {transform ? transform(stat.score) : stat.score}
              </Typography>
            </div>
          </Link>
        ))}
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          onClick={(): void => { dispatch(getLeaderboard(statistic, entity, material, limit)); }}
          size="small"
        >
          Refresh
        </Button>
      </CardActions>
    </Card>
  );
}

export default Leaderboard;
