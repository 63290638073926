import React from 'react';
import {
  Drawer as MUIDrawer,
  Hidden,
} from '@mui/material';
import { Props } from './types';
import useStyles from './useStyles';

function Drawer(props: Props): JSX.Element {
  const { open, onClose, children } = props;
  const { classes } = useStyles();
  return (
    <nav className={classes.drawer} aria-label="main menu">
      <Hidden mdUp implementation="css">
        <MUIDrawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={onClose}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
          data-test-id="drawer-temporary"
        >
          {children}
        </MUIDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <MUIDrawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
          data-test-id="drawer-permanent"
        >
          {children}
        </MUIDrawer>
      </Hidden>
    </nav>
  );
}

export default Drawer;
