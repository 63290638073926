import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&.loading': {
      opacity: 0.3,
    },
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-20px',
    marginTop: '-20px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  player: {
    margin: theme.spacing(4),
  },
}));

export default useStyles;
