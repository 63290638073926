import { makeStyles } from 'tss-react/mui';

// TODO: Extract drawer width to theme
const DRAWER_WIDTH = 240;

const useStyles = makeStyles()((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
}));

export default useStyles;
