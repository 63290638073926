import { Dispatch } from '..';
import Config from '../../modules/config';
import SculkwireService, { World } from '../../services/sculkwire';
import {
  Action,
  WorldsGetRequestAction,
  WorldsGetSuccessAction,
  WorldsGetErrorAction,
} from './types';

export const worldsGetRequest = (): WorldsGetRequestAction => ({
  type: Action.WORLDS_GET_REQUEST,
});

export const worldsGetSuccess = (worlds: World[]): WorldsGetSuccessAction => ({
  type: Action.WORLDS_GET_SUCCESS,
  payload: { worlds },
});

export const worldsGetError = (error: unknown): WorldsGetErrorAction => ({
  type: Action.WORLDS_GET_ERROR,
  payload: { error },
});

export const getWorlds = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(worldsGetRequest());
  try {
    const config = new Config();
    const worlds = await new SculkwireService(config.getSculkwireUrl()).getWorlds();
    dispatch(worldsGetSuccess(worlds));
  } catch (error) {
    dispatch(worldsGetError(error));
  }
};
