import { Config } from './types';

class NpmConfig implements Config {
  static DEFAULT_MC_URL = 'https://mc.dimension-seven.net';

  static DEFAULT_SCULKWIRE_PORT = '5443';

  static DEFAULT_DYNMAP_PORT = '8192';

  static DEFAULT_VERSION = 'x.y.z';

  private mcUrl: string;

  private sculkwirePort: string;

  private dynmapPort: string;

  private version: string;

  constructor() {
    this.mcUrl = process.env.REACT_APP_MC_URL || NpmConfig.DEFAULT_MC_URL;
    this.sculkwirePort = process.env.REACT_APP_SCULKWIRE_PORT || NpmConfig.DEFAULT_SCULKWIRE_PORT;
    this.dynmapPort = process.env.REACT_APP_DYNMAP_PORT || NpmConfig.DEFAULT_DYNMAP_PORT;
    this.version = process.env.REACT_APP_VERSION || NpmConfig.DEFAULT_VERSION;
  }

  getMcUrl(): string {
    return this.mcUrl;
  }

  getSculkwireUrl(): string {
    return `${this.mcUrl}:${this.sculkwirePort}`;
  }

  getDynmapUrl(): string {
    return `${this.mcUrl}:${this.dynmapPort}`;
  }

  getVersion(): string {
    return this.version;
  }
}

export default NpmConfig;
