import { Dispatch } from '..';
import Config from '../../modules/config';
import SculkwireService, { Material } from '../../services/sculkwire';
import {
  Action,
  MaterialsGetRequestAction,
  MaterialsGetSuccessAction,
  MaterialsGetErrorAction,
} from './types';

export const materialsGetRequest = (): MaterialsGetRequestAction => ({
  type: Action.MATERIALS_GET_REQUEST,
});

export const materialsGetSuccess = (materials: Material[]): MaterialsGetSuccessAction => ({
  type: Action.MATERIALS_GET_SUCCESS,
  payload: { materials },
});

export const materialsGetError = (error: unknown): MaterialsGetErrorAction => ({
  type: Action.MATERIALS_GET_ERROR,
  payload: { error },
});

export const getMaterials = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(materialsGetRequest());
  try {
    const config = new Config();
    const materials = await new SculkwireService(config.getSculkwireUrl()).getMaterials();
    dispatch(materialsGetSuccess(materials));
  } catch (error) {
    dispatch(materialsGetError(error));
  }
};
