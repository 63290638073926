import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  Typography,
  TextField,
} from '@mui/material';
import { idToTitle } from '../../modules/strings';
import {
  Entity,
  StatisticType,
  Material,
  Statistic,
} from '../../services/sculkwire';
import { Dispatch } from '../../store';
import { selectLoading } from '../../store/loading';
import { getStatistics, selectStatistics } from '../../store/statistics';
import { getMaterials, selectMaterials } from '../../store/materials';
import { getEntities, selectEntities } from '../../store/entities';
import useStyles from './useStyles';

type Item = { statistic: Statistic; material?: Material; entity?: Entity };

function LeaderboardList(): JSX.Element {
  const { classes } = useStyles();
  const dispatch = useDispatch<Dispatch>();
  const statistics = useSelector(selectStatistics);
  const materials = useSelector(selectMaterials);
  const entities = useSelector(selectEntities);
  const loading = useSelector(selectLoading(['STATISTICS_GET', 'MATERIALS_GET', 'ENTITIES_GET']));
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const pageSize = 50;

  useEffect(() => {
    dispatch(getStatistics());
    dispatch(getMaterials());
    dispatch(getEntities());
  }, [dispatch]);

  /**
   * Returns the title of the item.
   * @param item An item
   * @return A title string
   */
  function getTitle(item: Item): string {
    const s = idToTitle(item.statistic.id);
    const m = idToTitle(item.material?.id);
    const e = idToTitle(item.entity?.id);
    return `${s} ${m ?? e ?? ''}`.trim();
  }

  /**
   * Returns the relative link to the leaderboards page for the given item.
   * @param item An item
   * @return A link string
   */
  function getLink(item: Item): string {
    let result = `/leaderboards/${item.statistic.id}`;
    if (item.entity || item.material) result += '?';
    if (item.entity) result += `entity=${item.entity.id}`;
    if (item.material) result += `material=${item.material.id}`;
    return result;
  }

  /**
   * Compares two items alphabetically.
   * @param a An item
   * @param b Another item
   * @return -1 if a is less than b, or 1 if a is greater than b
   */
  function alphabetically(a: Item, b: Item): number {
    return getTitle(a).toLowerCase() < getTitle(b).toLowerCase() ? -1 : 1;
  }

  /**
   * Filter function to sort items by search terms.
   * @param item The item to sort
   * @return true if the item matches the search terms
   */
  function bySearchTerm(item: Item): boolean {
    const searchTerms = search.toLowerCase()
      .split(' ')
      .filter((x) => x !== '');
    let matches = 0;
    for (let i = 0; i < searchTerms.length; i += 1) {
      if (
        searchTerms[i] !== ''
        && (
          item.statistic.id.toLowerCase().includes(searchTerms[i])
          || item.entity?.id.toLowerCase().includes(searchTerms[i])
          || item.material?.id.toLowerCase().includes(searchTerms[i])
        )
      ) {
        matches += 1;
      }
    }
    return matches === searchTerms.length;
  }

  /**
   * Flattens statistics into a larger list of all combinations of statistic,
   * material, and entity.
   * @return A list of items representing all possible statistics
   */
  function flattenStatistics(): Item[] {
    const result: Item[] = [];
    statistics.forEach((statistic) => {
      switch (statistic.type) {
        case StatisticType.BLOCK:
          materials
            .filter((material) => material.isBlock)
            .forEach((material) => result.push({ statistic, material }));
          break;
        case StatisticType.ITEM:
          materials
            .filter((material) => material.isItem)
            .forEach((material) => result.push({ statistic, material }));
          break;
        case StatisticType.ENTITY:
          entities.forEach((entity) => result.push({ statistic, entity }));
          break;
        default:
          result.push({ statistic });
          break;
      }
    });
    return result.sort(alphabetically);
  }

  const items = useMemo<Item[]>(flattenStatistics, [statistics, materials, entities]);
  const filteredItems = items.filter(bySearchTerm);
  const pageItems = filteredItems.slice(page * pageSize, page * pageSize + pageSize);
  return (
    <Card className={classes.root}>
      <CardHeader title="Leaderboards" />
      {loading && <CircularProgress className={clsx(classes.loader, { loading })} />}
      <CardContent className={classes.content}>
        <div className={classes.innerContent}>
          <TextField
            placeholder="Search..."
            type="search"
            variant="outlined"
            onChange={(e): void => {
              setSearch(e.target.value);
              setPage(0);
            }}
            value={search}
          />
          <Table>
            <TableBody>
              {pageItems.map((item) => (
                <TableRow key={getTitle(item)}>
                  <TableCell>
                    <Link
                      to={getLink(item)}
                      className={classes.link}
                    >
                      <Typography variant="body1">
                        {getTitle(item)}
                      </Typography>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={filteredItems.length}
                  page={page}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[pageSize]}
                  onPageChange={(_e, p): void => setPage(p)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          onClick={(): void => { dispatch(getStatistics()); }}
          size="small"
        >
          Refresh
        </Button>
      </CardActions>
    </Card>
  );
}

export default LeaderboardList;
