import { Dispatch } from '..';
import SculkwireService, { Player } from '../../services/sculkwire';
import Config from '../../modules/config';
import {
  Action,
  PlayersGetRequestAction,
  PlayersGetSuccessAction,
  PlayersGetErrorAction,
  PlayersOnlineGetRequestAction,
  PlayersOnlineGetSuccessAction,
  PlayersOnlineGetErrorAction,
  PlayersPlayerGetRequestAction,
  PlayersPlayerGetSuccessAction,
  PlayersPlayerGetErrorAction,
} from './types';

export const playersGetRequest = (): PlayersGetRequestAction => ({
  type: Action.PLAYERS_GET_REQUEST,
});

export const playersGetSuccess = (players: Player[]): PlayersGetSuccessAction => ({
  type: Action.PLAYERS_GET_SUCCESS,
  payload: { players },
});

export const playersGetError = (error: unknown): PlayersGetErrorAction => ({
  type: Action.PLAYERS_GET_ERROR,
  payload: { error },
});

export const onlinePlayersGetRequest = (): PlayersOnlineGetRequestAction => ({
  type: Action.PLAYERS_ONLINE_GET_REQUEST,
});

export const onlinePlayersGetSuccess = (players: Player[]): PlayersOnlineGetSuccessAction => ({
  type: Action.PLAYERS_ONLINE_GET_SUCCESS,
  payload: { players },
});

export const onlinePlayersGetError = (error: unknown): PlayersOnlineGetErrorAction => ({
  type: Action.PLAYERS_ONLINE_GET_ERROR,
  payload: { error },
});

export const playerGetRequest = (): PlayersPlayerGetRequestAction => ({
  type: Action.PLAYERS_PLAYER_GET_REQUEST,
});

export const playerGetSuccess = (player: Player): PlayersPlayerGetSuccessAction => ({
  type: Action.PLAYERS_PLAYER_GET_SUCCESS,
  payload: { player },
});

export const playerGetError = (error: unknown): PlayersPlayerGetErrorAction => ({
  type: Action.PLAYERS_PLAYER_GET_ERROR,
  payload: { error },
});

export const getPlayers = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(playersGetRequest());
  try {
    const config = new Config();
    const players = await new SculkwireService(config.getSculkwireUrl()).getPlayers();
    dispatch(playersGetSuccess(players));
  } catch (error) {
    dispatch(playersGetError(error));
  }
};

export const getOnlinePlayers = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(onlinePlayersGetRequest());
  try {
    const config = new Config();
    const players = await new SculkwireService(config.getSculkwireUrl()).getOnlinePlayers();
    dispatch(onlinePlayersGetSuccess(players));
  } catch (error) {
    dispatch(onlinePlayersGetError(error));
  }
};

export const getPlayer = (uuid: string) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(playerGetRequest());
  try {
    const config = new Config();
    const player = await new SculkwireService(config.getSculkwireUrl()).getPlayer(uuid);
    dispatch(playerGetSuccess(player));
  } catch (error) {
    dispatch(playerGetError(error));
  }
};
