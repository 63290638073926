import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  card: {
    position: 'relative',
    minHeight: '55vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 0,
  },
  slide0: {
    backgroundImage: 'url("/slide-0.png")',
  },
  slide1: {
    backgroundImage: 'url("/slide-1.png")',
  },
  slide2: {
    backgroundImage: 'url("/slide-2.png")',
  },
  content: {
    color: 'white',
  },
  button: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 5,
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
}));

export default useStyles;
