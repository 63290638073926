import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Equalizer as LeaderboardIcon,
  Home as HomeIcon,
  AttachMoney as DonateIcon,
  Map as MapIcon,
  People as PeopleIcon,
} from '@mui/icons-material';
import { Feature, selectFeature } from '../../store/features';
import useStyles from './useStyles';

function MainMenu(): JSX.Element {
  const { classes } = useStyles();
  const location = useLocation();
  const mapEnabled = useSelector(selectFeature(Feature.MAP));
  const donationsEnabled = useSelector(selectFeature(Feature.DONATIONS));
  const items = [
    {
      text: 'Home',
      link: '/',
      icon: HomeIcon,
    },
    {
      text: 'Players',
      link: '/players',
      icon: PeopleIcon,
    },
    {
      text: 'Leaderboards',
      link: '/leaderboards',
      icon: LeaderboardIcon,
    },
  ];
  if (mapEnabled) {
    items.push({
      text: 'Map',
      link: '/map',
      icon: MapIcon,
    });
  }
  if (donationsEnabled) {
    items.push({
      text: 'Donate',
      link: '/donate',
      icon: DonateIcon,
    });
  }
  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {items.map((item) => (
          <ListItem button selected={location.pathname === item.link} key={item.link}>
            <Link className={classes.link} to={item.link}>
              <ListItemIcon>
                <item.icon />
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default MainMenu;
