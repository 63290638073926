import React from 'react';
import { Grid } from '@mui/material';
import LeaderboardList from '../LeaderboardList';
import useStyles from './useStyles';

function LeaderboardsPage(): JSX.Element {
  const { classes } = useStyles();
  return (
    <div className={classes.gridContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LeaderboardList />
        </Grid>
      </Grid>
    </div>
  );
}

export default LeaderboardsPage;
