import React from 'react';
import { Grid } from '@mui/material';
import PlayerList from '../PlayerList';
import useStyles from './useStyles';

function PlayersPage(): JSX.Element {
  const { classes } = useStyles();
  return (
    <div className={classes.gridContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PlayerList />
        </Grid>
      </Grid>
    </div>
  );
}

export default PlayersPage;
