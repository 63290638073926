import { CSSObject } from '@emotion/react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  link: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textDecoration: 'none',
    color: 'inherit',
  },
  toolbar: theme.mixins.toolbar as CSSObject,
}));

export default useStyles;
