import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import PlayerProfile from '../PlayerProfile';
import useStyles from './useStyles';

function PlayerPage(): JSX.Element {
  const { classes } = useStyles();
  const { uuid } = useParams();

  return (
    <div className={classes.gridContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <PlayerProfile uuid={uuid ?? ''} />
        </Grid>
        <Grid item xs={12} lg={6}>
          {/* <PlayerStatistics /> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default PlayerPage;
