import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { Player } from '../../services/sculkwire';
import { Dispatch } from '../../store';
import { getPlayers, selectAllPlayers } from '../../store/players';
import { selectLoading } from '../../store/loading';
import useStyles from './useStyles';

function PlayerList(): JSX.Element {
  const dispatch = useDispatch<Dispatch>();
  const players = useSelector(selectAllPlayers);
  const loading = useSelector(selectLoading('PLAYERS_GET'));
  const { classes } = useStyles();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const pageSize = 50;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    dispatch(getPlayers());
  }, [dispatch]);

  /**
   * Filter function to sort players by search terms.
   * @param player The player to sort
   * @return true if the player matches the search terms
   */
  function bySearchTerm(player: Player): boolean {
    const searchTerms = search.toLowerCase()
      .split(' ')
      .filter((x) => x !== '');
    let matches = 0;
    for (let i = 0; i < searchTerms.length; i += 1) {
      if (
        searchTerms[i] !== ''
        && (
          player.name.toLowerCase().includes(searchTerms[i])
          || player.uuid.toLowerCase().includes(searchTerms[i])
        )
      ) {
        matches += 1;
      }
    }
    return matches === searchTerms.length;
  }

  const filteredPlayers = players.filter(bySearchTerm);
  const pagePlayers = filteredPlayers.slice(page * pageSize, page * pageSize + pageSize);
  return (
    <Card className={classes.root}>
      <CardHeader title="Players" />
      {loading && <CircularProgress className={clsx(classes.loader, { loading })} />}
      <CardContent className={classes.content}>
        <div className={classes.innerContent}>
          <TextField
            placeholder="Search..."
            type="search"
            variant="outlined"
            onChange={(e): void => {
              setSearch(e.target.value);
              setPage(0);
            }}
            value={search}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">
                    Head
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    Name
                  </Typography>
                </TableCell>
                {!isMobile && [
                  <TableCell>
                    <Typography variant="body1">
                      Online
                    </Typography>
                  </TableCell>,
                  <TableCell>
                    <Typography variant="body1">
                      Joined
                    </Typography>
                  </TableCell>,
                  <TableCell>
                    <Typography variant="body1">
                      Last Seen
                    </Typography>
                  </TableCell>,
                ]}
              </TableRow>
            </TableHead>
            <TableBody>
              {pagePlayers.map((player) => (
                <TableRow
                  component={Link}
                  to={`/players/${player.uuid}`}
                  key={player.uuid}
                  className={classes.link}
                >
                  <TableCell>
                    <Avatar
                      src={`https://mc-heads.net/head/${player.uuid}`}
                      variant="square"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      {player.name}
                    </Typography>
                  </TableCell>
                  {!isMobile && [
                    <TableCell>
                      <Typography variant="body1">
                        {player.isOnline ? 'Online' : 'Offline'}
                      </Typography>
                    </TableCell>,
                    <TableCell>
                      <Typography variant="body1">
                        {new Date(player.firstPlayed).toLocaleString()}
                      </Typography>
                    </TableCell>,
                    <TableCell>
                      <Typography variant="body1">
                        {new Date(player.lastPlayed).toLocaleString()}
                      </Typography>
                    </TableCell>,
                  ]}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={filteredPlayers.length}
                  page={page}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[pageSize]}
                  onPageChange={(_e, p): void => setPage(p)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          onClick={(): void => { dispatch(getPlayers()); }}
          size="small"
        >
          Refresh
        </Button>
      </CardActions>
    </Card>
  );
}

export default PlayerList;
