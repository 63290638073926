import { AnyAction } from 'redux';
import { createTheme } from '@mui/material/styles';
import { Action, State } from './types';

export const DEFAULT_STATE: State = createTheme({
  palette: {
    mode: (localStorage.getItem('theme.palette.mode') === 'dark') ? 'dark' : 'light',
  },
});

// eslint-disable-next-line default-param-last
function reducer(state = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case Action.THEME_TYPE_SET: {
      const { paletteMode } = action.payload;
      localStorage.setItem('theme.palette.mode', paletteMode);
      return createTheme({
        palette: {
          mode: paletteMode,
        },
      });
    }
    default:
      return state;
  }
}

export default reducer;
