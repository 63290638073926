import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { Dispatch } from '../../store';
import { getWorlds, selectOverworld } from '../../store/worlds';
import { selectLoading } from '../../store/loading';
import useStyles from './useStyles';

function Weather(): JSX.Element | null {
  const { classes } = useStyles();
  const overworld = useSelector(selectOverworld);
  const loading = useSelector(selectLoading('WORLDS_GET'));
  const dispatch = useDispatch<Dispatch>();
  const isDay = overworld && overworld.time < 13000 && overworld.time > 1000;

  const getDescription = (): string => {
    if (overworld?.isThundering) {
      return 'Thunderstorm';
    }
    if (overworld?.isStorm) {
      return 'Rain';
    }
    return 'Clear';
  };

  // const getMoonPhase = (): number => {
  //   if (!overworld) {
  //     return 0;
  //   }
  //   const days = Math.floor(overworld.fullTime / 24000);
  //   return days % 8;
  // };

  useEffect(() => {
    dispatch(getWorlds());
  }, [dispatch]);

  return (
    <Card className={classes.root}>
      <CardHeader title="Weather" subheader="mc.dimension-seven.net" />
      {loading && <CircularProgress className={classes.loader} />}
      <CardContent className={clsx(classes.content, { loading })}>
        <CardMedia title="Weather">
          {overworld && overworld.isThundering && <img className={classes.thunderstorm} src="/thunderstorm.png" alt="thunderstorm" />}
          {overworld && !overworld.isThundering && overworld.isStorm && <img className={classes.rain} src="/rain.png" alt="rain" />}
          {overworld && !overworld.isThundering && !overworld.isStorm && isDay && <img className={classes.sun} src="/sun.png" alt="sun" />}
          {overworld && !overworld.isThundering && !overworld.isStorm && !isDay && (
            // <img className={classes.moon} src={`/moon/moon_${getMoonPhase()}.png`} alt="moon" />
            <img className={classes.moon} src="/moon/moon_0.png" alt="moon" />
          )}
        </CardMedia>
        <Typography variant="h5">
          {getDescription()}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={(): void => { dispatch(getWorlds()); }} size="small">Refresh</Button>
      </CardActions>
    </Card>
  );
}

export default Weather;
