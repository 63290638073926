import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Brightness7 as LightModeIcon,
  Brightness4 as DarkModeIcon,
} from '@mui/icons-material';
import { selectTheme, setThemeType } from '../../store/theme';
import { Props } from './types';
import useStyle from './useStyles';

function Header(props: Props): JSX.Element {
  const { onMenuClick } = props;
  const { classes } = useStyle();
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const isDarkMode = (theme.palette.mode === 'dark');
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={(): void => onMenuClick()}
          className={classes.menuButton}
          data-test-id="menu-button"
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6">
          Dimension-Seven
        </Typography>
        <Tooltip title="Toggle light/dark theme">
          <IconButton
            color="inherit"
            aria-label="toggle-dark-mode"
            onClick={(): void => { dispatch(setThemeType(isDarkMode ? 'light' : 'dark')); }}
            data-test-id="toggle-dark-mode-button"
          >
            {isDarkMode && <LightModeIcon />}
            {!isDarkMode && <DarkModeIcon />}
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
