import { Action as ReduxAction } from 'redux';
import { Player } from '../../services/sculkwire';

export interface State {
  online: Player[] | null;
  player: { [key: string]: Player };
}

export enum Action {
  PLAYERS_GET_REQUEST = 'PLAYERS_GET_REQUEST',
  PLAYERS_GET_SUCCESS = 'PLAYERS_GET_SUCCESS',
  PLAYERS_GET_ERROR = 'PLAYERS_GET_ERROR',
  PLAYERS_ONLINE_GET_REQUEST = 'PLAYERS_ONLINE_GET_REQUEST',
  PLAYERS_ONLINE_GET_SUCCESS = 'PLAYERS_ONLINE_GET_SUCCESS',
  PLAYERS_ONLINE_GET_ERROR = 'PLAYERS_ONLINE_GET_ERROR',
  PLAYERS_PLAYER_GET_REQUEST = 'PLAYERS_PLAYER_GET_REQUEST',
  PLAYERS_PLAYER_GET_SUCCESS = 'PLAYERS_PLAYER_GET_SUCCESS',
  PLAYERS_PLAYER_GET_ERROR = 'PLAYERS_PLAYER_GET_ERROR',
}

export type PlayersGetRequestAction = ReduxAction<Action.PLAYERS_GET_REQUEST>;

export interface PlayersGetSuccessAction extends ReduxAction<
  Action.PLAYERS_GET_SUCCESS
> {
  payload: {
    players: Player[];
  };
}

export interface PlayersGetErrorAction extends ReduxAction<Action.PLAYERS_GET_ERROR> {
  payload: {
    error: unknown;
  };
}

export type PlayersOnlineGetRequestAction = ReduxAction<Action.PLAYERS_ONLINE_GET_REQUEST>;

export interface PlayersOnlineGetSuccessAction extends ReduxAction<
  Action.PLAYERS_ONLINE_GET_SUCCESS
> {
  payload: {
    players: Player[];
  };
}

export interface PlayersOnlineGetErrorAction extends ReduxAction<Action.PLAYERS_ONLINE_GET_ERROR> {
  payload: {
    error: unknown;
  };
}

export type PlayersPlayerGetRequestAction = ReduxAction<Action.PLAYERS_PLAYER_GET_REQUEST>;

export interface PlayersPlayerGetSuccessAction extends ReduxAction<
  Action.PLAYERS_PLAYER_GET_SUCCESS
> {
  payload: {
    player: Player;
  };
}

export interface PlayersPlayerGetErrorAction extends ReduxAction<Action.PLAYERS_PLAYER_GET_ERROR> {
  payload: {
    error: unknown;
  };
}
