import { State as AppState } from '..';

export const selectLoading = (key: string | string[]) => (state: AppState): boolean => {
  if (typeof key === 'string') {
    return state.loading[key] || false;
  }
  let result = false;
  key.forEach((k) => {
    result = result || state.loading[k] || false;
  });
  return result;
};
