import React from 'react';
import { Link } from 'react-router-dom';
import ReactMUICarousel from 'react-material-ui-carousel';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@mui/material';
import clsx from 'clsx';
import useStyles from './useStyles';

function Carousel(): JSX.Element {
  const { classes } = useStyles();
  const items = [
    {
      title: 'Home',
      description: 'View server stats',
      button: {
        text: 'Home',
        link: '/',
      },
      className: classes.slide0,
    },
    {
      title: 'Map',
      description: 'An online live map',
      button: {
        text: 'Explore',
        link: '/map',
      },
      className: classes.slide1,
    },
    {
      title: 'Donate',
      description: 'Please help',
      button: {
        text: 'Donate',
        link: '/donate',
      },
      className: classes.slide2,
    },
  ];
  return (
    <ReactMUICarousel className={classes.root}>
      {
        items.map((item) => (
          <Card className={clsx(classes.card, item.className)} key={item.title}>
            <CardContent className={classes.content}>
              <Typography variant="h3">
                {item.title}
              </Typography>
              <Typography variant="h5" color="secondary">
                {item.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" className={classes.button}>
                <Link className={classes.link} to={item.button.link}>
                  {item.button.text}
                </Link>
              </Button>
            </CardActions>
          </Card>
        ))
      }
    </ReactMUICarousel>
  );
}

export default Carousel;
