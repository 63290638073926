import { Dispatch } from '..';
import Config from '../../modules/config';
import SculkwireService, { Entity } from '../../services/sculkwire';
import {
  Action,
  EntitiesGetRequestAction,
  EntitiesGetSuccessAction,
  EntitiesGetErrorAction,
} from './types';

export const entitiesGetRequest = (): EntitiesGetRequestAction => ({
  type: Action.ENTITIES_GET_REQUEST,
});

export const entitiesGetSuccess = (entities: Entity[]): EntitiesGetSuccessAction => ({
  type: Action.ENTITIES_GET_SUCCESS,
  payload: { entities },
});

export const entitiesGetError = (error: unknown): EntitiesGetErrorAction => ({
  type: Action.ENTITIES_GET_ERROR,
  payload: { error },
});

export const getEntities = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(entitiesGetRequest());
  try {
    const config = new Config();
    const entities = await new SculkwireService(config.getSculkwireUrl()).getEntities();
    dispatch(entitiesGetSuccess(entities));
  } catch (error) {
    dispatch(entitiesGetError(error));
  }
};
