import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
} from '@mui/material';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  CalendarToday as CalendarIcon,
} from '@mui/icons-material';
import clsx from 'clsx';
import { Dispatch } from '../../store';
import { getPlayer, selectPlayer } from '../../store/players';
import { selectLoading } from '../../store/loading';
import useStyles from './useStyles';
import { Props } from './types';

function PlayerProfile(props: Props): JSX.Element | null {
  const { uuid } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch<Dispatch>();
  const player = useSelector(selectPlayer(uuid));
  const loading = useSelector(selectLoading('PLAYERS_PLAYER_GET'));

  useEffect(() => {
    dispatch(getPlayer(uuid));
  }, [dispatch, uuid]);

  const booleanIcon = (bool?: boolean): JSX.Element => (bool ? <CheckIcon /> : <CloseIcon />);

  return (
    <Card className={classes.root}>
      <CardHeader title="Player Profile" />
      {loading && <CircularProgress className={classes.loader} />}
      <CardContent className={clsx(classes.content, { loading })}>
        <CardMedia title="Player profile">
          <img
            className={classes.image}
            src={player ? `https://mc-heads.net/body/${player.uuid}` : undefined}
            alt="player"
          />
        </CardMedia>
        <div>
          <Typography variant="h5">
            {player?.name}
          </Typography>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  {booleanIcon(player?.isOnline)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Online" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  {booleanIcon(player?.isBanned)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Banned" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  {booleanIcon(player?.isWhitelisted)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Whitelisted" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  {booleanIcon(player?.isOp)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Server Operator" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CalendarIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Joined" secondary={player && new Date(player.firstPlayed).toLocaleString()} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CalendarIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Last Seen" secondary={player && new Date(player.lastPlayed).toLocaleString()} />
            </ListItem>
          </List>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          onClick={(): void => { dispatch(getPlayer(uuid)); }}
          size="small"
        >
          Refresh
        </Button>
      </CardActions>
    </Card>
  );
}

export default PlayerProfile;
