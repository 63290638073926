import { Feature, State } from './types';

export const DEFAULT_STATE: State = {
  [Feature.MAP]: false,
  [Feature.DONATIONS]: false,
  [Feature.WEATHER]: true,
  [Feature.CAROUSEL]: false,
};

function reducer(state = DEFAULT_STATE): State {
  return state;
}

export default reducer;
