function transformTicksToTime(score: number) {
  const totalSeconds = score * 0.05;
  const hours = totalSeconds / 3600;
  const minutes = (hours % 1) * 60;
  const seconds = (minutes % 1) * 60;
  const displayHours = Math.floor(hours);
  const displayMinutes = Math.floor(minutes);
  const displaySeconds = Math.floor(seconds);
  let result = '';
  if (displayHours > 0) result += `${displayHours}h `;
  if (displayHours > 0 || displayMinutes > 0) result += `${displayMinutes}m `;
  result += `${displaySeconds}s`;
  return result;
}

export default transformTicksToTime;
