import React from 'react';
import { Typography } from '@mui/material';
import useStyles from './useStyles';

function NotFoundPage(): JSX.Element {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h1">
          404
        </Typography>
        <Typography variant="h2">
          Not Found
        </Typography>
      </div>
    </div>
  );
}

export default NotFoundPage;
