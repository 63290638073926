import { Action as ReduxAction } from 'redux';
import { Material } from '../../services/sculkwire';

export type State = Material[];

export enum Action {
  MATERIALS_GET_REQUEST = 'MATERIALS_GET_REQUEST',
  MATERIALS_GET_SUCCESS = 'MATERIALS_GET_SUCCESS',
  MATERIALS_GET_ERROR = 'MATERIALS_GET_ERROR',
}

export type MaterialsGetRequestAction = ReduxAction<Action.MATERIALS_GET_REQUEST>;

export interface MaterialsGetSuccessAction extends ReduxAction<Action.MATERIALS_GET_SUCCESS> {
  payload: {
    materials: Material[];
  };
}

export interface MaterialsGetErrorAction extends ReduxAction<Action.MATERIALS_GET_ERROR> {
  payload: {
    error: unknown;
  };
}
