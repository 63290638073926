import { AnyAction } from 'redux';
import { Player } from '../../services/sculkwire';
import { Action, State } from './types';

export const DEFAULT_STATE: State = {
  online: null,
  player: {},
};

// eslint-disable-next-line default-param-last
function reducer(state = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case Action.PLAYERS_GET_SUCCESS: {
      const { players } = action.payload;
      return {
        ...state,
        player: {
          ...state.player,
          ...players.reduce((
            accumulator: { [key: string]: Player },
            player: Player,
          ): { [key: string]: Player } => {
            accumulator[player.uuid] = player;
            return accumulator;
          }, {}),
        },
      };
    }
    case Action.PLAYERS_ONLINE_GET_SUCCESS: {
      const { players } = action.payload;
      return {
        ...state,
        online: players,
      };
    }
    case Action.PLAYERS_PLAYER_GET_SUCCESS: {
      const { player } = action.payload;
      return {
        ...state,
        player: {
          ...state.player,
          [player.uuid]: player,
        },
      };
    }
    default:
      return state;
  }
}

export default reducer;
