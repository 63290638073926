import { AnyAction } from 'redux';
import { Action, State } from './types';

export const DEFAULT_STATE: State = null;

// eslint-disable-next-line default-param-last
function reducer(state = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case Action.STATUS_GET_SUCCESS: {
      const { status } = action.payload;
      return status;
    }
    default:
      return state;
  }
}

export default reducer;
