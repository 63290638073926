export enum Feature {
  MAP,
  DONATIONS,
  WEATHER,
  CAROUSEL,
}

export interface State {
  [key: number]: boolean;
}
