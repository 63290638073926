import { Dispatch } from '..';
import Config from '../../modules/config';
import SculkwireService, { PlayerStat } from '../../services/sculkwire';
import {
  Action,
  LeaderboardGetRequestAction,
  LeaderboardGetSuccessAction,
  LeaderboardGetErrorAction,
} from './types';

export const leaderboardGetRequest = (): LeaderboardGetRequestAction => ({
  type: Action.LEADERBOARD_GET_REQUEST,
});

export const leaderboardGetSuccess = (
  statistic: string,
  data: PlayerStat[],
  entity?: string,
  material?: string,
  limit?: number,
): LeaderboardGetSuccessAction => ({
  type: Action.LEADERBOARD_GET_SUCCESS,
  payload: {
    statistic, data, entity, material, limit,
  },
});

export const leaderboardGetError = (error: unknown): LeaderboardGetErrorAction => ({
  type: Action.LEADERBOARD_GET_ERROR,
  payload: { error },
});

export const getLeaderboard = (
  statistic: string,
  entity?: string,
  material?: string,
  limit?: number,
) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(leaderboardGetRequest());
  try {
    const config = new Config();
    const data = await new SculkwireService(config.getSculkwireUrl()).getLeaderboard(
      statistic,
      entity,
      material,
      limit,
    );
    dispatch(leaderboardGetSuccess(
      statistic,
      data.scores,
      entity,
      material,
      limit,
    ));
  } catch (error) {
    dispatch(leaderboardGetError(error));
  }
};
