import { AnyAction } from 'redux';
import { Action, State } from './types';

export const DEFAULT_STATE: State = [];

// eslint-disable-next-line default-param-last
function reducer(state = DEFAULT_STATE, action: AnyAction): State {
  switch (action.type) {
    case Action.ENTITIES_GET_SUCCESS: {
      const { entities } = action.payload;
      return entities;
    }
    default:
      return state;
  }
}

export default reducer;
