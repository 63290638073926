import { AnyAction } from 'redux';
import { State } from './types';

export const DEFAULT_STATE: State = {};

// eslint-disable-next-line default-param-last
function reducer(state = DEFAULT_STATE, action: AnyAction): State {
  const regex = /(.*)_(REQUEST|SUCCESS|ERROR)/;
  const matches = regex.exec(action.type);
  if (matches) {
    const [, requestName, requestState] = matches;
    switch (requestState) {
      case 'REQUEST':
        return {
          ...state,
          [requestName]: true,
        };
      case 'SUCCESS':
        return {
          ...state,
          [requestName]: false,
        };
      case 'ERROR':
        return {
          ...state,
          [requestName]: false,
        };
      default:
        return state;
    }
  }
  return state;
}

export default reducer;
